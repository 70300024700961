<template>
    <form class="trip-correction-form l-stack l-gap-2" @submit.prevent="create">
        <h4 class="trip-correction-form-header">
            {{ $t('addCorrection') }}
        </h4>
        <div class="l-stack l-gap-1">
            <BaseTimeRange
                v-model="tripCorrection.time"
                :is-error="$v.tripCorrection.time.$error"
                :error-text="timeError"
                :disabled="tripCorrection.reason === 'NO_JOURNEY'"
            />
            <BaseMultiselect
                v-model="tripCorrection.type"
                :options="correctionTypes"
                :custom-label="option => $t(option)"
                :placeholder="$t('correctionType')"
                :is-error="$v.tripCorrection.type.$error"
                :error-text="typeError"
                block
            />

            <template v-if="tripCorrection.type === 'TRAILER'">
                <BaseMultiselect
                    v-model="tripCorrection.trailer"
                    :options="trailersExtended"
                    track-by="id"
                    :custom-label="option => option.name"
                    :placeholder="$t('trailer')"
                    :loading="isTrailersLoading"
                    :is-error="$v.tripCorrection.trailer.$error"
                    :error-text="trailerError"
                    label="label"
                    block
                />

                <template
                    v-if="
                        tripCorrection.trailer &&
                            tripCorrection.trailer.id !== 'NO_TRAILER'
                    "
                >
                    <BaseMultiselect
                        v-model="tripCorrection.trailerType"
                        :options="trailerTypes"
                        :custom-label="
                            option => $t(`shared.lsva.trailerTypes.${option}`)
                        "
                        :placeholder="$t('trailerType')"
                        :loading="isTrailersLoading"
                        :disabled="
                            tripCorrection.trailer &&
                                tripCorrection.trailer.id !== 'OTHER_TRAILER'
                        "
                        :is-error="$v.tripCorrection.trailerType.$error"
                        :error-text="trailerTypeError"
                        block
                    />

                    <BaseInput
                        v-model="tripCorrection.weight"
                        :placeholder="$t('weight')"
                        :disabled="
                            tripCorrection.trailer &&
                                tripCorrection.trailer.id !== 'OTHER_TRAILER'
                        "
                        :is-error="$v.tripCorrection.weight.$error"
                        :error-text="weightError"
                        type="number"
                        step="0.01"
                        block
                    >
                        <template #icon>
                            <ScalesIcon width="16" height="16" />
                        </template>
                        <template #postfix>
                            {{ $t('shared.units.tons') }}
                        </template>
                    </BaseInput>
                </template>
            </template>
            <BaseMultiselect
                v-else-if="tripCorrection.type === 'JOURNEY'"
                v-model="tripCorrection.reason"
                :options="journeyReasons"
                :custom-label="option => $t(option)"
                :placeholder="$t('journeyReason')"
                :is-error="$v.tripCorrection.reason.$error"
                :error-text="reasonError"
                block
            />
        </div>
        <div class="l-inline l-gap-1">
            <BaseButton
                size="small"
                :is-loading="isCorrectionPending"
                @click="create"
            >
                {{ $t('create') }}
            </BaseButton>
            <BaseButton size="small" variant="outline" @click="$emit('close')">
                {{ $t('cancel') }}
            </BaseButton>
        </div>
    </form>
</template>

<script>
import Vue from 'vue'
import { required, requiredIf } from 'vuelidate/lib/validators'
import moment from 'moment/moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import { httpHelper } from '@/utils'

import BaseButton from '@/components/redesigned/BaseButton.vue'
import BaseInput from '@/components/redesigned/BaseInput.vue'
import BaseMultiselect from '@/components/redesigned/BaseMultiselect.vue'
import BaseTimeRange from '@/components/base/BaseTimeRange.vue'
import ScalesIcon from '@/components/icons/ScalesIcon.vue'
import { CORRECTION_TYPES, JOURNEY_REASONS, TRAILER_TYPES } from '@/constants'

export default {
    name: 'LsvaTripCorrectionCreate',
    components: {
        BaseButton,
        BaseInput,
        BaseMultiselect,
        BaseTimeRange,
        ScalesIcon,
    },
    props: {
        trip: {
            type: Object,
            required: true,
        },
        tripCorrections: {
            type: Array,
            default: () => [],
        },
        allTrips: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        const tripStart =
            this.trip.type === 'TRACK'
                ? this.trip.correction_begin
                : this.trip.start

        const tripEnd =
            this.trip.type === 'TRACK'
                ? this.trip.correction_end
                : this.trip.end

        return {
            tripCorrection: {
                time: [
                    moment(tripStart.split('+')[0]).format('HH:mm'),
                    moment(tripEnd.split('+')[0]).format('HH:mm'),
                ],
                trailer: null,
                trailerType: null,
                weight: '',
                type: null,
                reason: null,
            },
        }
    },
    computed: {
        ...mapState('lsva', [
            'isCorrectionPending',
            'isTrailersLoading',
            'trailers',
            'selectedDay',
        ]),
        ...mapGetters('lsva', ['preparedTrips']),
        trailerTypes() {
            return TRAILER_TYPES
        },
        correctionTypes() {
            return CORRECTION_TYPES
        },
        journeyReasons() {
            if (
                this.preparedTrips.some(
                    correction => correction.reason === 'NO_JOURNEY'
                )
            ) {
                return JOURNEY_REASONS.filter(reason => reason !== 'NO_JOURNEY')
            }
            return JOURNEY_REASONS
        },
        trailersExtended() {
            return [
                { id: 'NO_TRAILER', name: this.$t('noTrailer') },
                ...this.trailers,
                { id: 'OTHER_TRAILER', name: this.$t('otherTrailer') },
            ]
        },
        timeError() {
            if (!this.$v.tripCorrection.time.$dirty) return ''
            if (!this.$v.tripCorrection.time.required)
                return this.$t('errors.timeRequired')
            if (!this.$v.tripCorrection.time.isEndTimeAfterStartTime)
                return this.$t('errors.endTimeAfterStartTime')
            if (!this.$v.tripCorrection.time.isTimeInsideParentTripTime)
                return this.$t('errors.timeInsideParentTripTime')
            if (!this.$v.tripCorrection.time.isTimeNotOverlapping)
                return this.$t('errors.isTimeNotOverlapping')
            return ''
        },
        typeError() {
            if (!this.$v.tripCorrection.type.$dirty) return ''
            if (!this.$v.tripCorrection.type.required)
                return this.$t('errors.typeRequired')
            return ''
        },
        trailerError() {
            if (!this.$v.tripCorrection.trailer.$dirty) return ''
            if (!this.$v.tripCorrection.trailer.required)
                return this.$t('errors.trailerRequired')
            return ''
        },
        weightError() {
            if (!this.$v.tripCorrection.weight.$dirty) return ''
            if (!this.$v.tripCorrection.weight.required)
                return this.$t('errors.weightRequired')
            return ''
        },
        trailerTypeError() {
            if (!this.$v.tripCorrection.trailerType.$dirty) return ''
            if (!this.$v.tripCorrection.trailerType.required)
                return this.$t('errors.trailerTypeRequired')
            return ''
        },
        reasonError() {
            if (!this.$v.tripCorrection.reason.$dirty) return ''
            if (!this.$v.tripCorrection.reason.required)
                return this.$t('errors.reasonRequired')
            return ''
        },
    },
    validations: {
        tripCorrection: {
            time: {
                required,
                isEndTimeAfterStartTime(value) {
                    return value[1] > value[0]
                },
                isTimeInsideParentTripTime(value) {
                    if (this.tripCorrection.reason === 'NO_JOURNEY') {
                        return true
                    }
                    const tripStart =
                        this.trip.type === 'TRACK'
                            ? this.trip.correction_begin
                            : this.trip.start

                    const tripEnd =
                        this.trip.type === 'TRACK'
                            ? this.trip.correction_end
                            : this.trip.end

                    return (
                        value[0] >=
                            moment(tripStart.split('+')[0]).format('HH:mm') &&
                        value[1] <=
                            moment(tripEnd.split('+')[0]).format('HH:mm')
                    )
                },
                isTimeNotOverlapping(value) {
                    return !this.tripCorrections.some(correction => {
                        const correctionStart = moment(
                            correction.correction_begin.split('+')[0],
                            'HH:mm'
                        )
                        const correctionEnd = moment(
                            correction.correction_end.split('+')[0],
                            'HH:mm'
                        )

                        const valueStart = moment(
                            `${this.selectedDay.date}T${value[0]}`,
                            'HH:mm'
                        )
                        const valueEnd = moment(
                            `${this.selectedDay.date}T${value[1]}`,
                            'HH:mm'
                        )

                        return (
                            valueStart.isBetween(
                                correctionStart,
                                correctionEnd,
                                'minute',
                                '[)'
                            ) ||
                            valueEnd.isBetween(
                                correctionStart,
                                correctionEnd,
                                'minute',
                                '(]'
                            )
                        )
                    })
                },
            },
            type: {
                required,
            },
            trailer: {
                required: requiredIf(vm => vm.type !== 'JOURNEY'),
            },
            weight: {
                required: requiredIf(
                    vm =>
                        vm.type !== 'JOURNEY' &&
                        vm.trailer?.id === 'OTHER_TRAILER'
                ),
            },
            trailerType: {
                required: requiredIf(
                    vm =>
                        vm.type !== 'JOURNEY' &&
                        vm.trailer?.id === 'OTHER_TRAILER'
                ),
            },
            reason: {
                required: requiredIf(vm => vm.type === 'JOURNEY'),
            },
        },
    },
    watch: {
        'tripCorrection.trailer'(trailer) {
            if (!trailer) {
                return
            }

            if (trailer.id === 'NO_TRAILER' || trailer.id === 'OTHER_TRAILER') {
                this.tripCorrection.trailerType = null
                this.tripCorrection.weight = ''
                return
            }

            this.tripCorrection.weight = (trailer.weight / 1000).toFixed(2)
            this.tripCorrection.trailerType = trailer.type
        },
        'tripCorrection.reason'(reason) {
            if (reason === 'NO_JOURNEY') {
                Vue.set(this.tripCorrection, 'time', ['00:00', '23:59:59'])
            }
        },
    },
    methods: {
        ...mapActions('lsva', ['createCorrection']),
        async create() {
            this.$v.$touch()

            if (this.$v.$invalid) {
                return
            }

            const notification = {
                title: this.$t('shared.success'),
                text: this.$t('correctionCreated'),
            }

            try {
                await this.createCorrection(this.tripCorrection)

                this.$notify(notification)
            } catch (error) {
                this.$notify({
                    title: this.$t('shared.error'),
                    text: httpHelper.parseError(error),
                })
            }

            this.$emit('close')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "addCorrection": "Add correction",
        "cancel": "Cancel",
        "correctionCreated": "Correction created",
        "create": "Save",
        "correctionType": "Correction Type",
        "TRAILER": "Is LSVA",
        "JOURNEY": "Is not LSVA",
        "journeyReason": "Journey reason",
        "NO_JOURNEY": "No journey",
        "MOVED_WITHOUT_JOURNEY_VEHICLE_LOADED": "Moved without journey, vehicle loaded",
        "MOVED_WITHOUT_JOURNEY_NON_TAXABLE_JOURNEY": "Moved without journey, non-taxable journey",
        "noTrailer": "No trailer",
        "otherTrailer": "Other trailer",
        "trailer": "Trailer",
        "trailerType": "Trailer type",
        "trip": "Trip",
        "warning": "Warning!",
        "weight": "Weight",
        "errors": {
            "timeRequired": "Time is required",
            "typeRequired": "Type is required",
            "endTimeAfterStartTime": "End time must be after start time",
            "timeInsideParentTripTime": "Time must be inside parent trip time",
            "isTimeNotOverlapping": "Time is overlapping with another correction",
            "distanceRequired": "Distance is required",
            "distanceMinValue": "Distance must be greater than 0",
            "trailerRequired": "Trailer is required",
            "weightRequired": "Weight is required",
            "trailerTypeRequired": "Trailer type is required",
            "messageRequired": "Message is required"
        }
    },
    "de": {
        "addCorrection": "Zwischenstrecke einfügen",
        "cancel": "Abbrechen",
        "confirmTripRevocation": "Möchten Sie diese Fahrt wirklich widerrufen?",
        "correctionCreated": "Zwischenstrecke erstellt",
        "create": "Ändern",
        "correctionType": "Zwischenstreckentyp",
        "TRAILER": "LSVA-pflichtige Fahrt",
        "JOURNEY": "Keine LSVA-pflichtige Fahrt",
        "journeyReason": "Fahrtgrund",
        "NO_JOURNEY": "Keine Fahrt",
        "MOVED_WITHOUT_JOURNEY_VEHICLE_LOADED": "LKW verladen",
        "MOVED_WITHOUT_JOURNEY_NON_TAXABLE_JOURNEY": "Fahrt ist nicht LSVA-pflichtig",
        "noTrailer": "Kein Anhänger",
        "otherTrailer": "Anderer Anhänger",
        "trailer": "Anhänger",
        "trailerType": "Anhängertyp",
        "trip": "Fahrt",
        "warning": "Warnung!",
        "weight": "Gewicht",
        "errors": {
            "timeRequired": "Zeit ist erforderlich",
            "typeRequired": "Typ ist erforderlich",
            "endTimeAfterStartTime": "Endzeit muss nach Startzeit liegen",
            "timeInsideParentTripTime": "Zeit muss innerhalb der Elternfahrt liegen",
            "isTimeNotOverlapping": "Zeit überschneidet sich mit einer anderen Korrektur",
            "distanceRequired": "Distanz ist erforderlich",
            "distanceMinValue": "Distanz muss größer als 0 sein",
            "trailerRequired": "Anhänger ist erforderlich",
            "weightRequired": "Gewicht ist erforderlich",
            "trailerTypeRequired": "Anhängertyp ist erforderlich",
            "messageRequired": "Nachricht ist erforderlich"
        }
    },
    "fr": {
        "addCorrection": "Ajouter une correction",
        "cancel": "Annuler",
        "confirmTripRevocation": "Voulez-vous vraiment révoquer ce trajet?",
        "correctionCreated": "Correction créée",
        "create": "Sauvegarder",
        "correctionType": "Type de correction",
        "TRAILER": "Est LSVA",
        "JOURNEY": "N'est pas LSVA",
        "journeyReason": "Raison du trajet",
        "NO_JOURNEY": "Pas de trajet",
        "MOVED_WITHOUT_JOURNEY_VEHICLE_LOADED": "Déplacé sans trajet, véhicule chargé",
        "MOVED_WITHOUT_JOURNEY_NON_TAXABLE_JOURNEY": "Déplacé sans trajet, trajet non imposable",
        "noTrailer": "Pas de remorque",
        "otherTrailer": "Autre remorque",
        "trailer": "Remorque",
        "trailerType": "Type de remorque",
        "trip": "Trajet",
        "warning": "Attention!",
        "weight": "Poids",
        "errors": {
            "timeRequired": "L'heure est requise",
            "typeRequired": "Le type est requis",
            "endTimeAfterStartTime": "L'heure de fin doit être postérieure à l'heure de début",
            "timeInsideParentTripTime": "L'heure doit être à l'intérieur de l'heure du trajet parent",
            "isTimeNotOverlapping": "L'heure se chevauche avec une autre correction",
            "distanceRequired": "La distance est requise",
            "distanceMinValue": "La distance doit être supérieure à 0",
            "trailerRequired": "La remorque est requise",
            "weightRequired": "Le poids est requis",
            "trailerTypeRequired": "Le type de remorque est requis",
            "messageRequired": "Le message est requis"
        }
    },
    "it": {
        "addCorrection": "Aggiungi correzione",
        "cancel": "Annulla",
        "confirmTripRevocation": "Vuoi davvero revocare questo viaggio?",
        "correctionCreated": "Correzione creata",
        "create": "Salva",
        "correctionType": "Tipo di correzione",
        "TRAILER": "LSVA",
        "JOURNEY": "Non LSVA",
        "journeyReason": "Motivo del viaggio",
        "NO_JOURNEY": "Nessun viaggio",
        "MOVED_WITHOUT_JOURNEY_VEHICLE_LOADED": "Spostato senza viaggio, veicolo caricato",
        "MOVED_WITHOUT_JOURNEY_NON_TAXABLE_JOURNEY": "Spostato senza viaggio, viaggio non tassabile",
        "noTrailer": "Nessun rimorchio",
        "otherTrailer": "Altro rimorchio",
        "trailer": "Rimorchio",
        "trailerType": "Tipo di rimorchio",
        "trip": "Viaggio",
        "warning": "Attenzione!",
        "weight": "Peso",
        "errors": {
            "timeRequired": "L'ora è obbligatoria",
            "typeRequired": "Il tipo è obbligatorio",
            "endTimeAfterStartTime": "L'ora di fine deve essere successiva all'ora di inizio",
            "timeInsideParentTripTime": "L'ora deve essere all'interno dell'ora del viaggio principale",
            "isTimeNotOverlapping": "L'ora si sovrappone a un'altra correzione",
            "distanceRequired": "La distanza è obbligatoria",
            "distanceMinValue": "La distanza deve essere maggiore di 0",
            "trailerRequired": "Il rimorchio è obbligatorio",
            "weightRequired": "Il peso è obbligatorio",
            "trailerTypeRequired": "Il tipo di rimorchio è obbligatorio",
            "messageRequired": "Il messaggio è obbligatorio"
        }
    }
}
</i18n>

<style lang="scss" scoped>
.trip-correction-form {
    padding: 24px;
    border-radius: 12px;
    background: $color-gray-light-2;

    &-header {
        font-size: 16px;
        font-weight: 700;
    }
}
</style>
