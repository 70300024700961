<template>
    <div class="base-time-range">
        <div class="base-time-range__container">
            <BaseInput
                v-model="timeStart"
                type="time"
                class="base-time-range__time"
                :placeholder="$t('from')"
                :is-error="isError"
                :disabled="disabled"
                block
            />
            <span>-</span>
            <BaseInput
                v-model="timeEnd"
                type="time"
                class="base-time-range__time"
                :placeholder="$t('to')"
                :is-error="isError"
                :disabled="disabled"
                block
            />
        </div>
        <div v-if="isError && errorText" class="base-time-range__error-text">
            {{ errorText }}
        </div>
    </div>
</template>
<script>
import BaseInput from '@/components/redesigned/BaseInput.vue'

export default {
    name: 'BaseTimeRange',
    components: {
        BaseInput,
    },
    props: {
        value: {
            type: Array,
            default: () => ['', ''], // [timeStart, timeEnd]
        },
        isError: {
            type: Boolean,
            default: false,
        },
        errorText: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        timeStart: {
            get() {
                return this.value[0]
            },
            set(value) {
                this.$emit('input', [value, this.value[1]])
            },
        },
        timeEnd: {
            get() {
                return this.value[1]
            },
            set(value) {
                this.$emit('input', [this.value[0], value])
            },
        },
    },
}
</script>

<i18n>
{
    "en": {
        "from": "From",
        "to": "To"
    },
    "de": {
        "from": "Von",
        "to": "Bis"
    },
    "fr": {
        "from": "De",
        "to": "À"
        },
    "it": {
        "from": "Da",
        "to": "A"
    }
}
</i18n>

<style lang="scss">
.base-time-range {
    &__container {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__time {
        flex-grow: 1;
    }

    &__error-text {
        color: $color-error;
        font-size: 11px;
        padding-top: 4px;
    }
}
</style>
