<template>
    <div
        class="lsva-trucks-view"
        :class="{
            'lsva-trucks-view--splitted': selectedTruck,
        }"
    >
        <div class="lsva-trucks-view__filters">
            <LsvaCalendarFilter />
        </div>
        <div class="lsva-trucks-view__split">
            <LsvaTrucksTableView
                class="lsva-trucks-view__table"
                :filter-search="filterSearch"
                :filter-status="filterStatus"
                @clearFilter="clearFilter"
            >
                <template #filter> <LsvaTrucksFilter /> </template>
            </LsvaTrucksTableView>

            <LsvaTruckDetailsView
                v-if="selectedTruck"
                :truck="selectedTruck"
                class="lsva-trucks-view__form"
                @close="setSelectedTruck(null)"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import LsvaTruckDetailsView from '@/components/views/lsva/LsvaTruckDetailsView.vue'
import LsvaTrucksTableView from './LsvaTrucksTableView.vue'
import LsvaCalendarFilter from '@/components/lsva/LsvaCalendarFilter.vue'
import LsvaTrucksFilter from '@/components/lsva/LsvaTrucksFilter.vue'

export default {
    name: 'LsvaTrucksView',
    components: {
        LsvaTrucksFilter,
        LsvaTruckDetailsView,
        LsvaCalendarFilter,
        LsvaTrucksTableView,
    },
    data() {
        return {
            filterSearch: '',
            filterStatus: [],
        }
    },
    computed: {
        ...mapState('lsva', ['searchStatus', 'selectedTruck']),
    },
    mounted() {
        this.loadTrailers()
    },
    methods: {
        ...mapActions('lsva', [
            'loadTrucks',
            'loadTrailers',
            'setSelectedTruck',
        ]),

        clearFilter() {
            this.filterSearch = ''
            this.filterType = []
        },
    },
}
</script>

<i18n>
{
    "en": {
        "filterByColor": "Color",
        "filterByLocation": "Location",
        "filterByMeasurements": "Filter by measurements",
        "filterByQuery": "Name",
        "filterByType": "Type",
        "share": "Share"
    },
    "de": {
        "filterByColor": "Farbe",
        "filterByLocation": "Standort",
        "filterByMeasurements": "Filtern nach Messungen",
        "filterByQuery": "Name",
        "filterByType": "Typ",
        "share": "Teilen"
    },
    "fr": {
        "filterByColor": "Couleur",
        "filterByLocation": "Emplacement",
        "filterByMeasurements": "Filtrer par mesures",
        "filterByQuery": "Nom",
        "filterByType": "Type",
        "share": "Partager"
    },
    "it": {
        "filterByColor": "Colore",
        "filterByLocation": "Locazione",
        "filterByMeasurements": "Filtro per misurazioni",
        "filterByQuery": "Name",
        "filterByType": "Typo",
        "share": "Condividi"
    }
}
</i18n>

<style lang="scss" scoped>
.lsva-trucks-view {
    $self: &;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__filters {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 32px 20px 0;
        border-bottom: $style-border;

        .base-input,
        .multiselect {
            max-width: 200px;
        }

        .base-button {
            padding: 10px 16px;
            background-color: #fff;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            line-height: 20px;
            font-weight: 600;
            font-size: 15px;
            color: $color-primary-text;

            &:disabled {
                color: rgba($color-primary-text, 0.25);
            }
        }
    }

    &__split {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        overflow-y: auto;

        & > div {
            width: 375px;
            height: 100%;
            overflow-y: auto;
        }

        #{$self}__table {
            width: 100%;
        }
    }

    &--splitted {
        #{$self}__table {
            width: calc(100% - 375px);
        }

        #{$self}__form {
            border-left: $style-border;
        }
    }

    @include respond-to('for-mobile-down') {
        &__split {
            flex-direction: column-reverse;

            & > div {
                width: 100% !important;
                height: auto;
                border-left: none !important;
                flex: 1 0 100%;
            }
        }

        &__split-icon {
            display: inline-block;
        }

        &--splitted {
            #{$self}__form {
                display: block;
            }

            #{$self}__table {
                display: none;
            }
        }
    }

    @include respond-to('for-small-mobile-only') {
        &__filters {
            padding: 1rem 1rem 0;

            & > * {
                &:not(.base-button) {
                    margin-right: 0;
                }
            }

            .base-input,
            .multiselect {
                max-width: 100%;
                width: 100%;
            }
        }
    }
}
</style>
